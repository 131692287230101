
import { defineComponent } from '@nuxtjs/composition-api';
import { VNode } from 'vue';
import { doTokenReplaceInArray } from '~/utils/token-replace';

export default defineComponent({
    name: 'LineBreakText',
    props: {
        text: {
            type: String,
            required: true,
        },
        tag: {
            type: String,
            default: 'span',
        },
    },
    render(createElement): VNode {
        // replace all '\n' in the given string with <br />
        const children: any[] = doTokenReplaceInArray([this.text], '\n', createElement('br'));
        return createElement(this.tag, children);
    },
});
