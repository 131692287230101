
import { defineComponent, PropType } from '@nuxtjs/composition-api';
import type { GlobalCtaLeafBannerBlokInterface } from '~/types/global';

export default defineComponent({
    name: 'GlobalCtaLeafBanner',
    props: {
        blok: {
            type: Object as PropType<GlobalCtaLeafBannerBlokInterface>,
            required: true,
        },
    },
});
